import { mapMutations, mapState, mapGetters } from 'vuex'
import L from '@/utils/leaflet.js'
import moment from 'moment'

import trackDirection from '@/mixins/trackDirection'

export default {
  mixins: [trackDirection],

  data() {
    return {
      programmaticMapManipulation: false,
      isMonitoringButtonArrow: false,
      deleteMarkerFromMonitoringFG: true,
      monitoringFeatureGroup: L.featureGroup(),
      monitoringMarkers: {},
      activeMonitoringTracks: {},
      activeTime: this.moment,
      currentPolyline: null,
      fastTrack: null,
      fixDate: null,
      arrDirectives: null,
      annotationLayer: null, // слой для аннотаций (необходим отдельный из-за перерисовки трека)
      zoomActive: false
    }
  },

  computed: {
    ...mapState('company', {
      currentTime: state => state.currentTime
    }),
    ...mapState('login', ['me']),
    ...mapState('monitoring', {
      monitoringMarker: state => state.marker,
      monitoringIdToShow: state => state.idToShow,
      cachedIdToShow: state => state.cachedIdToShow,
      markerToSetView: state => state.markerToSetView,
      followMarkers: state => state.arrowId,
      cachedArrowId: state => state.cachedArrowId,
      isClearingMonitoringTrack: state => state.isClearingMonitoringTrack,
      monitoringTrack: state => state.boxTrack,
      removingMarkers: state => state.removingMarkers,
      angles: state => state.angle
    }),
    ...mapState('units', {
      allUnits: state => state.units
    }),
    ...mapState('reports', {
      directiveZoom: state => state.directiveZoom
    }),
    ...mapGetters('units', ['unitId']),
    ...mapGetters({
      unitObject: 'monitoring/unitObjectWebSocket',
      track: 'monitoring/getTrack',
      boxTrackIsCentered: 'monitoringNew/boxTrackIsCentered',
      boxTrackIsOpened: 'monitoringNew/boxTrackIsOpened'
    }),
    monitoringUnits() {
      return this.allUnits.map(unit => {
        return {
          id: unit.id,
          icon: unit.icon ? unit.icon.key : 'van-vw_red',
          name: unit.name
        }
      })
    },
    statusIcons() {
      return this.$parent.statusIcons || {}
    },
    currentMarkers() {
      return this.$parent.activeMarkers
    },
    activeArrows() {
      return this.$parent.activeArrows
    }
  },

  watch: {
    isMonitoringButton(val) {
      if (val) {
        this.activateMonitoring()
      }
    },
    isMonitoringButtonArrow(val) {
      if (val) {
        this.activateMonitoring()
      }
    },
    fastTrack(newVal, oldVal) {
      if (oldVal && this.map.hasLayer(oldVal)) {
        this.map.removeLayer(oldVal)
      }
    },
    boxTrackIsOpened(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        // закрываем открытый popup
        this.clearPopupsAnnotationInLayer()
      }
    },

    currentPolyline(val) {
      const canv = document.getElementsByTagName('canvas')

      if (canv.length > 1) {
        if (navigator.userAgent.indexOf('Chrome') !== -1) {
          canv[0].style.zIndex = '101'
          canv[1].style.zIndex = '101'
        }
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
          canv[1].style.zIndex = '101'
          canv[0].style.zIndex = '100'
        }
      }
    },

    removingMarkers(val) {
      Object.keys(this.monitoringMarkers).forEach(marker => {
        this.monitoringMarkers[marker].removeFrom(this.map)
        if (this.markersObjectGroup) {
          this.markersObjectGroup.removeLayer(this.monitoringMarkers[marker])
        }
      })

      if (val.length) {
        const lays = this.monitoringFeatureGroup.getLayers()

        lays.forEach(lay => lay.removeFrom(this.map))
        this.monitoringFeatureGroup.clearLayers()
      }
    },

    monitoringMarker(val) {
      Object.keys(val).forEach(markerID => {
        const markerData = val[markerID]
        this.addMonitoringMarker(markerID, markerData)
      })
    },

    markerToSetView(val) {
      const currentMarkers = L.featureGroup()
      // if there is more than one object on the map ( val = {'': false} )  by default
      if (Object.keys(val).length > 2) {
        Object.keys(val).forEach(x => {
          this.monitoringMarkers[x] !== undefined && currentMarkers.addLayer(this.monitoringMarkers[x])
        })
        // make dynamic padding based on monitoring window's size
        const monitoringContainer = document.getElementById('monitoring-container').offsetWidth

        this.map.fitBounds(currentMarkers.getBounds(), {
          paddingTopLeft: [monitoringContainer, 0]
        })
      } else {
        // if there is only one object on the map
        const markerID = Object.keys(val)[1]
        this.map.setView(this.monitoringMarkers[markerID].getLatLng(), 13)
      }
    },

    monitoringIdToShow(val) {
      const markerID = Object.keys(val)[0]
      if (Object.keys(this.monitoringMarkers).includes(markerID)) {
        this.toggleMonitoringMarker(markerID)
      }
    },

    currentTime(val) {
      if (this.fixDate === null) {
        this.fixDate = val
      }
      const time = new Date(val) - new Date(this.fixDate)
      if (time > 60000) {
        Object.keys(this.monitoringMarker).forEach(markerID => {
          const markerData = this.monitoringMarker[markerID]
          this.addMonitoringMarker(markerID, markerData)
          this.fixDate = val
        })
      }
    },

    cachedIdToShow(val) {
      if (val) {
        if (Object.keys(val).length) {
          this.SET_OBJECT_MARKER('clear')
        }
        Object.keys(val).forEach(markerID => {
          const markerData = val[markerID]
          this.addCachedMarker(markerID, markerData)
        })
      }
    },

    cachedArrowId: {
      handler({ markersToAdd, markersToDelete }) {
        markersToDelete.forEach(markerId => {
          const marker = this.monitoringMarkers[markerId]
          marker.removeFrom(this.monitoringFeatureGroup)
          this.markersObjectGroup.addLayer(marker)
        })

        Object.keys(markersToAdd).forEach(markerID => {
          const markerData = markersToAdd[markerID]
          this.addCachedArrow(markerID, markerData)
        })

        this.activateMonitoring()
      },
      deep: true
    },

    followMarkers(val) {
      const markerID = Object.keys(val)[0]
      const marker = this.monitoringMarkers[markerID]
      if (!marker || !marker._icon) {
        return
      }
      if (this.monitoringFeatureGroup.hasLayer(marker)) {
        console.log('follow markers remove')
        marker.removeFrom(this.monitoringFeatureGroup)
        this.markersObjectGroup.removeLayer(marker)
        this.activateMonitoring()
      } else {
        console.log('follow markers add')
        marker.addTo(this.monitoringFeatureGroup)
        this.activateMonitoring()
        this.markersObjectGroup.addLayer(marker)
      }
    },

    monitoringTrack(val) {
      if (!val) return
      // console.log('Watcher change monitoring track')
      const trackId = Object.keys(val)[0]
      const trackData = Object.values(val)[0]
      this.arrDirectives = { track: val[trackId] }

      this.lastDistance = 0
      this.DIRECTIVE_ZOOM(true)
      if (this.listMarkers.reports) {
        this.zoomReports = true
        this.zoomMonitoring = true
        this.zoomReportsTrack = false
      } else {
        this.zoomReportsTrack = false
        this.zoomReports = false
        this.zoomMonitoring = true
      }

      this.map.on('moveend', () => {
        if (this.arrDirectives && this.directiveZoom && this.zoomMonitoring && this.isMonitoring) {
          this.showDirectivesDebounce(this.arrDirectives, 'monitoring')
        }
      })

      this.showDirectivesDebounce(this.arrDirectives, 'monitoring')

      this.addMonitoringTrack(trackId, trackData)
    },

    unitObject() {
      // если активные стрелочки то делаем zoom
      if (this.activeArrows.length > 0) {
        this.zoomCurrentItems()
      }
    },

    unitId(newVal, oldVal) {
      const marker = this.monitoringMarkers[newVal]
      if (marker) {
        marker.removeFrom(this.map)
        this.markersObjectGroup.removeLayer(marker)
      }
    }
  },

  methods: {
    ...mapMutations('reports', ['DIRECTIVE_ZOOM']),
    ...mapMutations('monitoring', ['SET_OBJECT_MARKER']),
    ...mapMutations('map', ['SET_RADIUS_VALUE']),
    ...mapMutations({
      setPolyline: 'monitoringNew/setPolyline'
    }),
    ...mapMutations({ setBoxTrackCentered: 'monitoringNew/setBoxTrackCentered' }),

    _moment(date) {
      if (this.me.lang.key === 'kz') {
        moment.locale('kk')
      } else {
        moment.locale(this.me.lang.key)
      }
      const now = moment(this.currentTime, 'YYYY-MM-DD HH:mm:ss')
      const duration = moment.duration(now.diff(moment(date, 'YYYY-MM-DD HH:mm:ss')))
      if (duration > 2600000 && duration < 3600000) {
        const fixMinute = moment.duration(2600000).humanize().toString()
        const text = fixMinute.split('43 ')[1]
        const minute = Math.floor(duration / 60000)
        return `${minute} ${text}`
      }
      return moment.duration(duration._milliseconds).humanize()
    },
    createMarkerIconHtml(iconUrl, angle = -1, color = '', unit_id = `unit_id_${Math.random()}`) {
      // найдем имя иконки
      const index = iconUrl.lastIndexOf('_')
      const correctIconUrl = iconUrl.substring(0, index)
      const arrIcon = correctIconUrl.split('/')
      const currentIcon = arrIcon.pop()
      const colors = [
        { name: 'red', color: '#eb5757' },
        { name: 'green', color: '#1bb934' },
        { name: 'blue', color: '#1585d8' },
        { name: 'grey', color: '#808080bf' },
        { name: 'orange', color: '#e68a2f' }
      ]

      // найдем цвет иконки
      const urlColor = iconUrl.split('_')
      const clr = urlColor.pop()
      const colorIndex = clr.lastIndexOf('.')
      const correctColor = clr.substring(0, colorIndex)

      let currentColorIcon = ''
      colors.forEach(el => {
        if (el.name === correctColor) {
          currentColorIcon = el.color
        }
      })
      const img = `
        <div class='skif-marker__img-box'>
          <span class="skif-marker__item">
            <svg class="skif-marker__img" width="56" height="50" viewBox="0 -4 56 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="/static/car_icon-new/cars.svg#${currentIcon}" fill="${currentColorIcon}"></use>
            </svg>
          </span>
        </div>
      `
      const angleStr = angle !== -1 ? `style='transform: rotate(${angle}deg); -moz-transform: rotate(${angle}deg); ' ` : `style='display: none;'`

      const arrow = `<div id='unit_id_${unit_id}' class='skif-marker__arrow-box' ${angleStr}></div>`
      const colorStr = color ? `style='color: ${color};'` : ''
      return `<div class='skif-marker__box' ${colorStr}'>${arrow}${img}</div>`
    },
    createMarker(unit, markerID, markerData) {
      // console.log('createMarker', unit)
      const iconUrl = `/static/car_icon/${unit.icon}.svg`
      const dateValue = this.unitObject[markerID].columns.filter(row => {
        return row.key === 'datepoint'
      })[0].value
      const activeSeconds = new Date(dateValue)
      const currentSeconds = new Date(this.currentTime)
      const differenceSecond = (currentSeconds - activeSeconds) / 1000
      const angle = this.angles[markerID] || -1
      const color = differenceSecond < 900 ? '#4ABC96' : differenceSecond < 3600 ? '#F2C94C' : '#A0A0A0'
      const icon = L.divIcon({
        iconSize: [45, 61],
        car: unit.icon,
        className: 'skif-marker  --arrows',
        html: this.createMarkerIconHtml(iconUrl, angle, color, markerID)
      })
      const marker = L.marker(markerData, {
        icon,
        draggable: false,
        unitId: markerID
      })
      return marker
    },
    resetMonitoring() {
      this.$emit('isTrackLegend', false)
      if (this.activeMonitoringTracks) {
        Object.values(this.activeMonitoringTracks).forEach(track => {
          track.off('click')
          track.removeFrom(this.map)
        })
        this.activeMonitoringTracks = {}
      }
    },
    addCachedArrow(markerID, markerData) {
      if (this.me.lang.key === 'kz') {
        moment.locale('kk')
      } else {
        moment.locale(this.me.lang.key)
      }

      let tooltipTable = ''
      this.unitObject[markerID].columns.forEach((row, key) => {
        if (row.key.charAt(0) !== '_')
          tooltipTable += `<tr class="border-b last_border-b-0"><td class="px-2 py-1 text-annotationColor font-semibold border-l-0 border-r-0 font-SourceSansPro text-base">${row.name} </td>
        <td class="px-2 py-1 text-darkblue font-bold border-l-0 border-r-0 text-sm">${row.key === 'datepoint' ? `${row.value}<br> ( ${this._moment(this.unitObject[markerID].columns[key + 1].value)} ${this.$t('back')} )` : row.value}</td></tr>`
      })

      const unit = this.monitoringUnits.find(unit => unit.id === markerID)
      if (!unit) return
      const unitName = unit.name
      const marker = this.createMarker(unit, markerID, markerData).bindTooltip(unitName, {
        permanent: true,
        className: 'unitName',
        direction: 'bottom',
        offset: [0, 18]
      })
      marker.id = markerID
      this.monitoringMarkers[markerID] = marker
      const self = this
      const currentMarker = this.monitoringMarkers[markerID]

      currentMarker.on('click', function () {
        self.isAnno = true
        self.currentAnnotation = self.unitObject[unit.id]
      })

      if (this.markersObjectGroup.hasLayer(this.monitoringMarkers[markerID])) {
        this.markersObjectGroup.removeLayer(this.monitoringMarkers[markerID])
      }
      this.SET_OBJECT_MARKER(marker)
      this.markersObjectGroup.addLayer(marker)
      this.monitoringMarkers[markerID].addTo(this.monitoringFeatureGroup)
      this.$store.commit('reports/SET_DRAWENMARKERS', this.monitoringMarkers)
    },
    addCachedMarker(markerID, markerData) {
      if (this.me.lang.key === 'kz') {
        moment.locale('kk')
      } else {
        moment.locale(this.me.lang.key)
      }

      let tooltipTable = ''

      this.unitObject[markerID].columns.forEach((row, key) => {
        if (row.key.charAt(0) !== '_')
          tooltipTable += `<tr class="border-b last_border-b-0"><td class="px-2 py-1 text-annotationColor font-semibold border-l-0 border-r-0 font-SourceSansPro text-base">${row.name} </td>
                <td class="px-2 py-1 text-darkblue font-bold border-l-0 border-r-0 text-sm">${row.key === 'datepoint' ? `${row.value}<br> ( ${this._moment(this.unitObject[markerID].columns[key + 1].value)} ${this.$t('back')} )` : row.value}</td>
              </tr>`
      })
      const unit = this.monitoringUnits.find(unit => unit.id === markerID)

      if (!unit) return
      const unitName = unit.name
      const marker = this.createMarker(unit, markerID, markerData).bindTooltip(unitName, {
        permanent: true,
        className: 'unitName',
        direction: 'bottom',
        offset: [0, 18]
      })
      marker.on('click', event => {
        this.$store.dispatch('monitoring/CLOSEANNOMONITORINGANNO')
        this.currentAnnotation = this.unitObject[unit.id]

        this.isAnno = true
      })
      marker.id = markerID
      this.markersObjectGroup.addLayer(marker)
      this.map.addLayer(this.markersObjectGroup)
      this.monitoringMarkers[markerID] = marker

      this.SET_OBJECT_MARKER(marker)
      this.$store.commit('reports/SET_DRAWENMARKERS', this.monitoringMarkers)
      this.SET_RADIUS_VALUE(100)
    },
    addMonitoringMarker(markerID, markerData) {
      if (this.me.lang.key === 'kz') {
        moment.locale('kk')
      } else {
        moment.locale(this.me.lang.key)
      }
      let tooltipTable = ''

      if (this.unitObject && this.unitObject[markerID] && this.unitObject[markerID].columns) {
        this.unitObject[markerID].columns.forEach((row, key) => {
          if (row.key.charAt(0) !== '_')
            tooltipTable += `<tr class="border-b last_border-b-0"><td class="px-2 py-1 text-annotationColor font-semibold border-l-0 border-r-0 font-SourceSansPro text-base">${row.name} </td>
        <td class="px-2 py-1 text-darkblue font-bold border-l-0 border-r-0 text-sm">${row.key === 'datepoint' ? `${row.value}<br> ( ${this._moment(this.unitObject[markerID].columns[key + 1].value)} ${this.$t('back')} )` : row.value}</td></tr>`
        })

        const anno = this.unitObject[markerID].columns.filter(k => k.key.charAt(0) !== '_')

        const formattedAnno = anno.reduce(function (results, row) {
          ;(results[row.group] = results[row.group] || []).push(row)
          return results
        }, {})

        let title
        anno.forEach((row, index) => {
          if (row.group === 'main') {
            if (row.key === 'datepoint') {
            } else if (row.key === 'unit_name') {
              title = row.value
            }
          }
        })
        let content = ''
        content += `
          <span class='text-darkblue font-semibold font-SourceSansPro title'> ${title} </span>
            <span class='text-annotationColor font-semibold font-SourceSansPro title'> times Ago </span>
        `

        Object.keys(formattedAnno).forEach((annotation, index) => {
          let elContainer
          formattedAnno[annotation].forEach(el => {
            elContainer = ` <div> ${el.name}</div>`
          })
          content += `
        <div class='flex flex-col'>
          <span class='text-darkblue font-bold font-SourceSansPro title sub-title  w-full'> ${annotation !== 'main' && annotation === 'gps' ? 'GPS-ДАННЫЕ :' : 'ДОПОЛНИТЕЛЬНО :'}
          ${elContainer}
        </div>

        `
        })
        tooltipTable += `
            <div class='flex flex-col p-4 pb-3 annotation_container' style='height:300px; max-width:300px'>
              ${content}
            </div>

          `

        if (Object.keys(this.monitoringMarkers).includes(markerID)) {
          this.monitoringMarkers[markerID].setLatLng(L.latLng(markerData[0], markerData[1])).setPopupContent(tooltipTable)

          const currentMarker = this.monitoringMarkers[markerID]
          const unit = this.monitoringUnits.find(unit => unit.id === markerID)
          if (!unit) return
          const iconUrl = `/static/car_icon/${unit.icon}.svg`

          const dateValue = this.unitObject[markerID].columns.filter(row => {
            return row.key === 'datepoint'
          })[0].value
          let activeSeconds = new Date(dateValue)
          activeSeconds = activeSeconds.getTime() / 1000
          const currentSeconds = new Date(this.currentTime).getTime() / 1000
          const differenceSecond = currentSeconds - activeSeconds
          const angle = this.angles[markerID] || -1
          const color = differenceSecond < 900 ? '#4ABC96' : differenceSecond < 3600 ? '#F2C94C' : '#A0A0A0'
          const icon = L.divIcon({
            iconSize: [45, 61],
            car: unit.icon,
            className: 'skif-marker --arrows',
            html: this.createMarkerIconHtml(iconUrl, angle, color, markerID)
          })
          currentMarker.setIcon(icon)
          const self = this
          currentMarker.on('click', function () {
            self.isAnno = true
            self.currentAnnotation = self.unitObject[unit.id]
          })
        } else {
          const unit = this.monitoringUnits.find(unit => unit.id === markerID)
          if (!unit) return
          const unitName = unit.name
          const currentAngle = this.angles[markerID]
          const marker = this.createMarker(unit, markerID, markerData, currentAngle).bindTooltip(unitName, {
            permanent: true,
            className: 'unitName',
            direction: 'bottom',
            offset: [0, 18]
          })
          marker.id = markerID
          this.monitoringMarkers[markerID] = marker
        }
        this.activateMonitoringByMarker(this.monitoringMarkers[markerID])
      }
    },
    activateMonitoringByMarker(marker) {
      if (this.monitoringFeatureGroup.hasLayer(marker) && this.isMonitoringButtonArrow) {
        this.activateMonitoring()
      }
    },
    activateMonitoring() {
      const monitoringLayers = this.monitoringFeatureGroup.getLayers()
      if (!monitoringLayers.length) return
      this.$emit('setMonitoringButton', true)
      this.programmaticMapManipulation = true

      if (monitoringLayers.length > 1) {
        if (this.isMonitoringButtonArrow) {
          this.map.fitBounds(this.monitoringFeatureGroup.getBounds())
        }
      } else if (this.isMonitoringButtonArrow) {
        this.map.setView(monitoringLayers[0].getLatLng())
      }

      setTimeout(() => (this.programmaticMapManipulation = false), 100)
    },
    zoomCurrentItems() {
      const { currentMarkers } = this
      if (currentMarkers.length) {
        const coords = []
        currentMarkers.forEach(markerId => {
          const markerObj = this.monitoringMarkers[markerId] || null
          if (markerObj && markerObj._latlng) {
            coords.push(markerObj._latlng)
          }
        })
        if (coords.length > 0) {
          const bounds = L.latLngBounds(coords)
          this.map.fitBounds(bounds, {
            paddingTopLeft: L.point(50, 10),
            paddingBottomRight: L.point(10, 10)
          })
        }
      }
    },//
    toggleMonitoringMarker(id) {
      const marker = this.monitoringMarkers[id]
      if (this.monitoringFeatureGroup.hasLayer(marker)) {
        marker.removeFrom(this.monitoringFeatureGroup)
        this.markersObjectGroup.removeLayer(marker)
      } else if (this.markersObjectGroup && this.markersObjectGroup.hasLayer(marker)) {
        marker.removeFrom(this.map)
        this.markersObjectGroup.removeLayer(marker)
      } else {
        this.markersObjectGroup.addLayer(marker)
        this.map.addLayer(this.markersObjectGroup)
      }
    },
    // Срабатывает когда показываем быстрый трек

    showTrack(tracksArr, defaultStyle = { color: '#e31e24' }) {
      console.log(tracksArr, defaultStyle,'hello monitoring');
      // Create a dictionary for quick search of styles based on new_styles_key(new fast track)

      const styleDict = {}
      tracksArr?.legenda?.newStyles.forEach(style => {
        styleDict[style.key] = style.hex
      })
      return tracksArr.values.map(track => {
        const newStylesKey = track.new_style_key
        const color = styleDict[newStylesKey] || defaultStyle.color

        const points = track.points.map(p => [p[1], p[2]])
        const time = Object.fromEntries(track.points.map(p => [p[0], L.latLng(p[1], p[2])]))
        const unit_id = track.unit_id || null
        return L.polyline(points, {
          color: color,
          shadowStyle: track.shadowLayer,
          borderLayer: track.borderLayer,
          weight: this.currentWeights,
          time,
          unit_id,
          originalStyle: color,
        })
      })
    },

    // Добавление трека в мониторинг
    addMonitoringTrack(trackId, trackData) {
      console.log('🚀🚀🚀 ~ addMonitoringTrack ~ trackData:', trackData)
      if (this.me.lang.key === 'kz') {
        moment.locale('kk')
      } else {
        moment.locale(this.me.lang.key)
      }

      if (Object.keys(this.activeMonitoringTracks).includes(trackId)) {
        this.activeMonitoringTracks[trackId].off('click')
        this.activeMonitoringTracks[trackId].removeFrom(this.map)

        delete this.activeMonitoringTracks[trackId]
      }

      const track = this.showTrack(trackData, this.styles)

      // Генерация случайного цвета
      // const randomColor = '#' + Math.floor(Math.random()*16777215).toString(16);
      const polyline = L.featureGroup(track)
      this.setPolyline(polyline)

      this.activeMonitoringTracks[trackId] = polyline
      this.currentPolyline = polyline

      polyline.options.unitId = trackId
      // polyline.setStyle({ color: randomColor });
      polyline.addTo(this.map)

      this.fastTrack = polyline

      // Клик по треку - когда вызвали Быстрый трек из мониторинга - показываем аннотацию
      polyline.on('click', target => {
        console.log('PolylineClick & Show track annotation ')
        this.showTrackAnnotationToLayer(target, trackId, target.latlng, trackData, this.activeMonitoringTracks[trackId])
      })

    }
  }
}
